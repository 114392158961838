import React, { useCallback, useEffect, useState } from "react";
import clsx from "clsx";
import { Box } from "@material-ui/core";
import { RouteComponentProps } from "react-router";
import dayjs from "dayjs";
import {
  CancelCopy,
  CancelScenario,
  CancelScenarioEnum,
  Maybe,
  PackageItinerary,
  SelfServeEvents,
} from "redmond";
import {
  ActionButton,
  ActionLink,
  B2BSpinnerWithText,
  GenericModalContent,
  HotelSummaryPanel,
  Icon,
  IconName,
  ImportantInfoList,
} from "halifax";

import { PENDING_CANCELLATION } from "../../../FlightCard/constants";
import { trackEvent } from "../../../../../../../../api/v1/analytics/trackEvent";
import confirmHotelCancellation from "../../../../../../../../api/v1/itinerary/confirmHotelCancellation";
import getHotelChangeInfo from "../../../../../../../../api/v1/itinerary/getHotelChangeInfo";
import * as constants from "../../constants";
import {
  PATH_HOME,
  CONTACT_SUPPORT_URL,
} from "../../../../../../../../utils/paths";

import "./styles.scss";

import { ChangePackageHotelModalContentConnectorProps } from "./container";

enum ModalFlowStepEnum {
  loading = "loading",
  policy = "policy",
  confirm = "confirm",
  success = "success",
  support = "support",
  pending = "pending",
  canceled = "canceled",
  tryAgain = "tryAgain",
}

interface IChangePackageHotelModalContentProps
  extends ChangePackageHotelModalContentConnectorProps,
    RouteComponentProps {
  packageItinerary: PackageItinerary;
  isMobile?: boolean;
}

const renderContactSupportButton = () => (
  <ActionButton
    className="b2b"
    defaultStyle="h4r-primary"
    message={constants.CONTACT_SUPPORT}
    onClick={() => {
      trackEvent({
        eventName: SelfServeEvents.ClickSupport,
        properties: {
          product: "hotel",
        },
      });

      window.open(CONTACT_SUPPORT_URL, "_blank")?.focus();
    }}
  />
);

const renderSuccessModal = (closeModal: () => void) => (
  <GenericModalContent
    className="hotel-change-modal-success"
    title={constants.CANCELLATION_SUCCESS_TITLE}
    subtitle={constants.CANCELLATION_SUCCESS_COPY}
    image={<Icon className="success-icon" name={IconName.Checked} />}
    actions={
      <ActionButton
        defaultStyle="h4r-primary"
        message={constants.CLOSE}
        onClick={closeModal}
      />
    }
  />
);

const renderContactSupport = (
  title: string,
  subtitle: string[],
  closeModal: () => void
) => (
  <GenericModalContent
    className="hotel-change-modal-contact-support"
    title={title}
    subtitle={subtitle}
    actions={
      <>
        {renderContactSupportButton()}
        <ActionButton
          defaultStyle="h4r-secondary"
          message={constants.CLOSE}
          onClick={closeModal}
        />
      </>
    }
  />
);

const renderTryAgainModal = (
  title: string,
  subtitle: string[],
  counter: number,
  tryAgain: () => void
) => (
  <GenericModalContent
    className="hotel-cancel-modal-try-again"
    title={title}
    subtitle={subtitle}
    actions={
      <>
        {counter >= 3 && renderContactSupportButton()}
        {counter < 3 && (
          <ActionButton
            defaultStyle="h4r-secondary"
            message={constants.TRY_AGAIN}
            onClick={tryAgain}
          />
        )}
      </>
    }
  />
);

const renderPendingModal = (closeModal: () => void) => (
  <GenericModalContent
    className="hotel-cancel-modal-try-again"
    title={constants.CANCELLATION_PENDING}
    subtitle={PENDING_CANCELLATION}
    actions={
      <>
        <ActionButton
          defaultStyle="h4r-secondary"
          message={constants.CLOSE}
          onClick={closeModal}
        />
      </>
    }
  />
);

export const ChangePackageHotelModalContent = (
  props: IChangePackageHotelModalContentProps
) => {
  const { setOpenModal, packageItinerary, isMobile = false, history } = props;
  const hotel = packageItinerary.hotel.itinerary;
  const [cancelScenario, setCancelScenario] =
    useState<Maybe<CancelScenario>>(null);
  const reservationBookingId = hotel?.reservation?.reservationBookingId;
  const [modalFlowStep, setModalFlowStep] = useState<ModalFlowStepEnum>(
    ModalFlowStepEnum.loading
  );
  const [tryAgain, setTryAgain] = useState<number>(0);

  const goToConfirm = useCallback(() => {
    trackEvent({
      eventName: SelfServeEvents.ClickCancel,
      properties: {
        product: "hotel",
        lob: "hotel",
        ...hotel.reservation.trackingPropertiesV2.properties,
      },
      encryptedProperties: [
        hotel.reservation.trackingPropertiesV2.encryptedProperties ?? "",
      ],
    });
    setModalFlowStep(ModalFlowStepEnum.confirm);
  }, []);

  const getPolicyInfo = useCallback(() => {
    setModalFlowStep(ModalFlowStepEnum.loading);
    getHotelChangeInfo({
      reservationId: reservationBookingId,
    })
      .then((hotelPolicy) => {
        setCancelScenario(hotelPolicy);
        switch (hotelPolicy.HotelCancelScenario) {
          case CancelScenarioEnum.Pending:
          case CancelScenarioEnum.CancellationFailure:
            return setModalFlowStep(ModalFlowStepEnum.pending);

          case CancelScenarioEnum.ContactCustomerService:
          case CancelScenarioEnum.FullyRefundableComplex:
          case CancelScenarioEnum.PartialRefund:
          case CancelScenarioEnum.PartiallyRefundableComplex:
            return setModalFlowStep(ModalFlowStepEnum.support);

          case CancelScenarioEnum.Canceled:
          case CancelScenarioEnum.NonRefundable:
          case CancelScenarioEnum.FullRefund:
            return setModalFlowStep(ModalFlowStepEnum.policy);

          default:
            return setModalFlowStep(ModalFlowStepEnum.loading);
        }
      })
      .catch(() => {
        setModalFlowStep(ModalFlowStepEnum.tryAgain);
      });
  }, [reservationBookingId]);

  useEffect(() => {
    if (hotel) {
      trackEvent({
        eventName: SelfServeEvents.ViewedCancelModal,
        properties: {
          product: "hotel",
          lob: "hotel",
          ...hotel.reservation.trackingPropertiesV2.properties,
        },
        encryptedProperties: [
          hotel.reservation.trackingPropertiesV2.encryptedProperties ?? "",
        ],
      });

      getPolicyInfo();
    }
  }, [hotel, getPolicyInfo]);

  const closeModal = () =>
    setOpenModal({ type: null, selectedItinerary: null });

  const backToMyTrips = useCallback(() => {
    closeModal();
    history.push(`${PATH_HOME}?tripId=${hotel.reservation.reservationId}`);
    window.location.reload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hotel]);

  const handleHotelCancel = useCallback(() => {
    trackEvent({
      eventName: SelfServeEvents.ConfirmCancellation,
      properties: {
        product: "hotel",
        lob: "hotel",
        ...hotel.reservation.trackingPropertiesV2.properties,
      },
      encryptedProperties: [
        hotel.reservation.trackingPropertiesV2.encryptedProperties ?? "",
      ],
    });

    setModalFlowStep(ModalFlowStepEnum.loading);
    if (cancelScenario && reservationBookingId) {
      confirmHotelCancellation(
        {
          reservationId: reservationBookingId,
          cancelScenario,
        },
        hotel
      )
        .then(() => {
          setModalFlowStep(ModalFlowStepEnum.success);
        })
        .catch(() => {
          setModalFlowStep(ModalFlowStepEnum.tryAgain);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cancelScenario, reservationBookingId, setOpenModal]);

  const renderActions = useCallback(
    (isConfirm: boolean, preparedPayment?: string) => {
      return preparedPayment ? (
        <ActionButton
          className={clsx("hotel-change-modal-action-button", {
            red: isConfirm,
          })}
          defaultStyle={"h4r-primary"}
          onClick={isConfirm ? handleHotelCancel : goToConfirm}
          message={
            isConfirm ? constants.CONFIRM_CANCEL : constants.CANCEL_HOTEL
          }
        />
      ) : (
        <ActionLink
          className="hotel-change-modal-action-link"
          onClick={closeModal}
          content={constants.BACK_TO_MY_TRIPS}
        />
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleHotelCancel, hotel]
  );
  const renderPolicyContent = useCallback((copyObj?: CancelCopy) => {
    return (
      <Box className="hotel-change-modal-content">
        <HotelSummaryPanel
          hideImage
          isMobile={isMobile}
          hideStarRating
          selectedLodging={{
            lodging: lodgingData,
            description: lodgingData.description,
            isPreferred: false,
            isFreeCancel: false,
            isLuxuryCollection: false,
          }}
          checkIn={checkInDate ? dayjs(checkInDate).toDate() : null}
          checkOut={checkOutDate ? dayjs(checkOutDate).toDate() : null}
        />
        {!!copyObj?.importantInfo.length ? (
          <ImportantInfoList
            title="Cancellation Information"
            infoItems={copyObj?.importantInfo}
          />
        ) : null}
      </Box>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    cancelCopy,
    cancelConfirmationCopy,
    customerServiceCopy,
    preparedPayment,
  } = cancelScenario || {};
  const { reservation } = hotel || {};
  const { lodgingData, checkInDate, checkOutDate } = reservation || {};

  switch (modalFlowStep) {
    case ModalFlowStepEnum.support:
      return renderContactSupport(
        customerServiceCopy!.title,
        customerServiceCopy!.body,
        closeModal
      );
    case ModalFlowStepEnum.policy:
    case ModalFlowStepEnum.confirm:
      const isConfirm = modalFlowStep === ModalFlowStepEnum.confirm;
      const copyObj = isConfirm ? cancelConfirmationCopy : cancelCopy;
      return (
        <GenericModalContent
          className="hotel-change-modal-container"
          title={copyObj?.title}
          subtitle={copyObj?.body}
          actions={renderActions(isConfirm, preparedPayment)}
          content={renderPolicyContent(copyObj)}
        />
      );
    case ModalFlowStepEnum.success:
      return renderSuccessModal(backToMyTrips);
    case ModalFlowStepEnum.tryAgain:
      return renderTryAgainModal(
        "Something went wrong",
        ["try again"],
        tryAgain,
        () => {
          setTryAgain(tryAgain + 1);
          getPolicyInfo();
        }
      );
    case ModalFlowStepEnum.pending:
      return renderPendingModal(closeModal);
    default:
      return <B2BSpinnerWithText subtitle={constants.LOADING_HOTEL_POLICY} />;
  }
};
