import { Box, Popover, Typography } from "@material-ui/core";
import React, { useRef, useState } from "react";
import * as textConstants from "../PackageCard/constants";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  AirlineMap,
  getDepartureSlice,
  getFlightInfoDetails,
  getReturnSlice,
  MyTripsFilter,
  MyTripsModalTypes,
  PortalItineraryStatusEnum,
  ScheduleChangeSeverity,
} from "redmond";
import { RouteComponentProps } from "react-router-dom";
import { MobilePackageFlightDetailsConnectorProps } from "./container";
import { getTitleTag } from "../FlightCard/component";
import { IActionLink, ActionLinks, StatusTag } from "halifax";
import { addPackageType } from "../PackageCard/DesktopPackageHotelCard/component";
import dayjs from "dayjs";
import clsx from "clsx";
import { ConfirmationSummary } from "../ConfirmationSummary";
import { getConfirmationNumbers } from "../ItinerariesModal/components/ConfirmationModalContent/component";
import { PaymentSummary } from "../PaymentSummary";
import { SummaryCard } from "../SummaryCard";
import { TravelersSummary } from "../TravelersSummary";
import { ItinerarySummary } from "../ItinerarySummary";
import { getCurrencyString } from "../../../../../../utils/helpers";
import { MobileActionLinks } from "../MobileActionLinks";
import { HOW_REFUNDS_WORK_TEXT } from "../../constants";

export interface IMobilePackageFlightDetailsProps
  extends RouteComponentProps,
    MobilePackageFlightDetailsConnectorProps {}

export const MobilePackageFlightDetails = ({
  packageFlight,
  setSelectedPackageFlight,
  tripsFilter,
  history,
  packageItinerary,
  setOpenModal,
  airlineMap,
  airportMap,
}: IMobilePackageFlightDetailsProps) => {
  const [refundsPopoverOpen, setRefundsPopoverOpen] = useState(false);
  const closeRefundsPopover = () => setRefundsPopoverOpen(false);
  const refundsPopoverRef = useRef<HTMLButtonElement>(null);

  if (!packageFlight || !packageItinerary) {
    return null;
  }
  const {
    status,
    travelCredit,
    travelCreditCopy = "",
    bookedItinerary,
  } = packageFlight;
  const { pricingBreakdown, paymentBreakdown } = packageItinerary;

  const hasFTC = !!travelCredit && !!travelCreditCopy;
  const isCanceled = status === PortalItineraryStatusEnum.Canceled;
  const isPastTrips = tripsFilter === MyTripsFilter.PAST_TRIPS;

  const hasMajorScheduleChange =
    bookedItinerary.scheduleChange?.severity === ScheduleChangeSeverity.Major;

  const formatDate = (date: string) =>
    dayjs(date).format(textConstants.DATE_FORMAT);

  const { confirmationCode, subtitle, title } = getFlightInfoDetails(
    bookedItinerary.travelItinerary.locators,
    getDepartureSlice(bookedItinerary),
    getReturnSlice(bookedItinerary),
    formatDate,
    airportMap,
    airlineMap
  );
  const onOpenModal = (type: MyTripsModalTypes) => {
    setOpenModal({
      type,
      selectedItinerary: addPackageType(packageItinerary),
    });
  };

  const topActions: IActionLink[] = []; // renders at the top of the page

  const getActions = () => {
    const actions: IActionLink[] = [];

    if (!isCanceled && !isPastTrips) {
      actions.push({
        content: textConstants.CANCEL_FLIGHT,
        onClick: () => onOpenModal(MyTripsModalTypes.SelfServeCancelFlight),
      });
      actions.push({
        content: textConstants.CHANGE_FLIGHT,
        onClick: () => onOpenModal(MyTripsModalTypes.ExchangeFlight),
      });
    }

    // always show this option regardless of cancellation status
    actions.push({
      content: textConstants.RESEND_CONFIRMATION,
      onClick: () => onOpenModal(MyTripsModalTypes.ResendConfirmation),
    });

    return actions;
  };

  return (
    <Box className="mobile-trip-details">
      <Box className="mobile-trip-details-header">
        <FontAwesomeIcon
          icon={faChevronLeft}
          onClick={() => {
            history.goBack();
            setSelectedPackageFlight(null);
          }}
        />
        <Box className="header-info">
          <Typography variant="subtitle1">
            {textConstants.getFlightHeader(packageFlight)}
          </Typography>
          <Typography variant="caption">
            {getReturnSlice(packageFlight.bookedItinerary)
              ? textConstants.ROUND_TRIP
              : textConstants.ONE_WAY}
          </Typography>
        </Box>
      </Box>
      {/* {mobileBanner} */}
      {topActions.length ? (
        <>
          <ActionLinks actions={topActions} />
        </>
      ) : null}{" "}
      <Box className="mobile-trip-card mobile-trip-summary-card">
        {!!getTitleTag(packageFlight, airlineMap as AirlineMap) && (
          <StatusTag
            className="title-status-tag"
            tagInfo={
              getTitleTag(
                packageFlight,
                airlineMap as AirlineMap,
                hasMajorScheduleChange
              )!
            }
          />
        )}
        <Typography variant="body1" className="trips-title">
          {title}
        </Typography>
        {subtitle && <Typography variant="caption">{subtitle}</Typography>}
        {confirmationCode && (
          <Box className="info-container">
            <Typography className="label" variant="caption">
              {textConstants.CONFIRMATION}
            </Typography>
            <Typography
              className={clsx("code", {
                warning: isCanceled,
              })}
              variant="caption"
            >
              {confirmationCode}
            </Typography>
          </Box>
        )}
      </Box>
      <Box className={"mobile-summary-card-section"}>
        <SummaryCard
          className="mobile-trip-card mobile-outgoing-itinerary-summary"
          action={
            <FontAwesomeIcon
              className="mobile-right-chevron"
              onClick={() => onOpenModal(MyTripsModalTypes.OutboundItinerary)}
              icon={faChevronRight}
            />
          }
        >
          <ItinerarySummary
            flight={packageFlight}
            isOutgoing
            airlineMap={airlineMap}
            airportMap={airportMap}
          />
        </SummaryCard>
        {!!getReturnSlice(bookedItinerary) && (
          <SummaryCard
            className="mobile-trip-card"
            action={
              <FontAwesomeIcon
                className="mobile-right-chevron"
                onClick={() => onOpenModal(MyTripsModalTypes.ReturnItinerary)}
                icon={faChevronRight}
              />
            }
          >
            <ItinerarySummary
              flight={packageFlight}
              isOutgoing={false}
              airlineMap={airlineMap}
              airportMap={airportMap}
            />
          </SummaryCard>
        )}
        <SummaryCard
          className="mobile-trip-card"
          action={
            <FontAwesomeIcon
              className="mobile-right-chevron"
              onClick={() => onOpenModal(MyTripsModalTypes.TravelersModal)}
              icon={faChevronRight}
            />
          }
        >
          <TravelersSummary
            travelers={bookedItinerary.passengers.alone
              .map(({ person }) => person?.givenName)
              .join(", ")}
          />
        </SummaryCard>
        {paymentBreakdown && (
          <SummaryCard
            className="mobile-payment-summary mobile-trip-card"
            action={
              <FontAwesomeIcon
                className="mobile-right-chevron"
                onClick={() => onOpenModal(MyTripsModalTypes.PaymentModal)}
                icon={faChevronRight}
              />
            }
          >
            <PaymentSummary
              tripTotalAmount={getCurrencyString(
                pricingBreakdown.payNowAmount.fiat
              )}
              cardLabel={
                textConstants.getLabelsFromPaymentBreakdown(paymentBreakdown)
                  .cardLabel
              }
              cardAmount={textConstants.getValues(paymentBreakdown).cardValue}
              rewardsLabel={
                textConstants.getLabelsFromPaymentBreakdown(paymentBreakdown)
                  .rewardLabel
              }
              rewardsAmount={
                textConstants.getValues(paymentBreakdown).rewardValue
              }
              showCardLabel={Boolean(
                textConstants.getLabelsFromPaymentBreakdown(paymentBreakdown)
                  .cardLabel
              )}
              showRewardsLabel={Boolean(
                textConstants.getLabelsFromPaymentBreakdown(paymentBreakdown)
                  .rewardLabel
              )}
            />
          </SummaryCard>
        )}
        <SummaryCard
          className="mobile-trip-card"
          action={
            <Box
              className="confirmation-mobile-action"
              onClick={() => onOpenModal(MyTripsModalTypes.ConfirmationModal)}
            >
              <Typography className="view-all-link">
                {`View all (${
                  getConfirmationNumbers({ flight: packageFlight, airlineMap })
                    .length
                })`}
              </Typography>
              <FontAwesomeIcon
                className="mobile-right-chevron"
                icon={faChevronRight}
              />
            </Box>
          }
        >
          <ConfirmationSummary confirmationCode={confirmationCode || ""} />
        </SummaryCard>
      </Box>
      <MobileActionLinks actions={getActions()} />{" "}
      {isCanceled && !hasFTC && (
        <Popover
          anchorEl={refundsPopoverRef.current}
          anchorOrigin={{
            horizontal: "left",
            vertical: "bottom",
          }}
          className="how-refunds-work-popover"
          onClose={closeRefundsPopover}
          open={refundsPopoverOpen}
        >
          <Typography className="subtitle">{HOW_REFUNDS_WORK_TEXT}</Typography>
        </Popover>
      )}
    </Box>
  );
};
